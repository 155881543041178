<template>
    <div class="app-indexpage launchpage u-rela">
        <div class="u-rela">
            <img
                    class="u-abso imgcenter" :src="image"
            />
            <img class="bg turn_loading_image" src="../../assets/images/applet/index/launch-2.png"/>
            <div class="loading_scanning"></div>
        </div>
        <div class="u-flex u-flex-col">
            <span class="blod u-font-48 green2">{{ num }}%</span>
            <span class="u-main-color u-font-32 u-m-b-60">追溯中</span>
            <img
                    class="u-m-b-100 linebg"
                    src="../../assets/images/applet/index/launch-4.png"
            />
            <transition appear name="text" @appear="appearHook">
                <div class="u-flex u-flex-col text">
          <span ref="sp1" class="u-font-28 u-m-b-18">
            权威检测：
            <i class="green2">可追溯</i>
            <i class="iconfont iconcheck_fill green2 u-font-28 u-m-l-20"></i>
          </span>
                    <span ref="sp2" class="u-font-28 u-m-b-18">
            产地信息：
            <i class="green2">可追溯</i>
            <i class="iconfont iconcheck_fill green2 u-font-28 u-m-l-20"></i>
          </span>
                    <span ref="sp3" class="u-font-28 u-m-b-18">
            种植环节：
            <i class="green2">可追溯</i>
            <i class="iconfont iconcheck_fill green2 u-font-28 u-m-l-20"></i>
          </span>
                    <span ref="sp4" class="u-font-28 u-m-b-18">
            加工环节：
            <i class="green2">可追溯</i>
            <i class="iconfont iconcheck_fill green2 u-font-28 u-m-l-20"></i>
          </span>
                    <span ref="sp5" class="u-font-28 u-m-b-18">
            生产企业：
            <i class="green2">可追溯</i>
            <i class="iconfont iconcheck_fill green2 u-font-28 u-m-l-20"></i>
          </span>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
    import Velocity from "velocity-animate";

    export default {
        name: "",
        data() {
            return {
                num: 0,
                image: ''
            };
        },
        mounted() {
            this.count();
            this.open();
        },
        methods: {
            count() {
                var timer = setInterval(() => {
                    if (this.num < 100) {
                        this.num++;
                    } else {
                        clearInterval(timer);
                    }
                    if (this.image == '' && this.$parent.good.loading_img != undefined) {
                        this.image = this.$parent.good.loading_img;
                    }
                }, 10);
            },

            appearHook() {
                var that = this;
                Velocity(
                    this.$refs.sp1,
                    {
                        opacity: 1,
                    },
                    {
                        duration: 500,
                        delay: 500,
                    }
                );
                Velocity(
                    this.$refs.sp2,
                    {
                        opacity: 0,
                    },
                    {
                        duration: 250,
                    }
                );
                Velocity(
                    this.$refs.sp2,
                    {
                        opacity: 0.7,
                    },
                    {
                        duration: 750,
                        delay: 500,
                    }
                );
                Velocity(
                    this.$refs.sp3,
                    {
                        opacity: 0,
                    },
                    {
                        duration: 500,
                    }
                );
                Velocity(
                    this.$refs.sp3,
                    {
                        opacity: 0.5,
                    },
                    {
                        duration: 500,
                        delay: 750,
                    }
                );
                Velocity(
                    this.$refs.sp4,
                    {
                        opacity: 0,
                    },
                    {
                        duration: 500,
                    }
                );
                Velocity(
                    this.$refs.sp4,
                    {
                        opacity: 0.4,
                    },
                    {
                        duration: 1000,
                        delay: 1000,
                    }
                );
                Velocity(
                    this.$refs.sp5,
                    {
                        opacity: 0,
                    },
                    {
                        duration: 750,
                    }
                );
                Velocity(
                    this.$refs.sp5,
                    {
                        opacity: 0.3,
                    },
                    {
                        duration: 1000,
                        delay: 1250,
                    }
                );
            },
            open() {
                // this.$router.push({path:'/applet/index',query:{setid:123456}});

            },
        },
    };
</script>
<style lang="scss">
    @import "../../assets/css/applet.scss";
</style>
